/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
    map, catchError
} from "rxjs/operators";
import {
    Observable, throwError
} from "rxjs";

import {
    Injectable, Inject, ErrorHandler
} from "@angular/core";
import {
    HttpClient, HttpParams, HttpHeaders
} from "@angular/common/http";

import {
    ILocation, Results, Chain
} from "@shopliftr/common-js/shared";

import { CommonErrorHandler } from "@shopliftr/common-ng";
import { UnderscoreCase } from "@shopliftr/common-js/cerialize";

/**
 * Service to get available chains in the area
 */
@Injectable({
    providedIn: "root"
})
export class ChainService {

    private readonly _v3chainsUrl: string;

    constructor(
        private readonly _httpClient: HttpClient,
        @Inject(ErrorHandler) private readonly _errorHandler: CommonErrorHandler,
        @Inject("AppConfig") private readonly _config: any
    ) {

        this._v3chainsUrl = `${this._config.searchAPIUrl}/v3/chains`;
    }


    /**
     * Will get chains that are available in that area - will not return stores, but only chains
     *
     * @param  location
     * @param  [name]
     * @param  [ids]
     * @returns
     * @memberof ChainService
     */
    availableNearby(location: ILocation, name?: string, ids?: Array<string>): Observable<Results<Chain>> {

        const nearbyUrl = this._v3chainsUrl + "/nearby";
        let queryParams: HttpParams = new HttpParams();

        for (const field in location) {
            if (field) {
                queryParams = queryParams.set(field, location[field]);
            }
        }
        if (name) {
            queryParams = queryParams.set("name", name);
        }
        if (ids) {
            let idString = "";
            for (const id of ids) {
                if (idString.length > 1) {
                    idString = idString + ",";
                }
                idString = `${idString}${id}`;
            }
            queryParams = queryParams.set("id", idString);
        }

        const headers: HttpHeaders = this._getHeaders();
        return this._httpClient.get<any>(nearbyUrl, {
            headers: headers,
            params: queryParams
        }).pipe(map((data) => {

            const results: Results<Chain> = Results.deserialize(data, UnderscoreCase, Chain);
            return results;
        }), catchError((error: any): Observable<any> => {

            return throwError(() => this._errorHandler.handleHttpClientError(error));
        }));

    }


    protected _getHeaders(): HttpHeaders {

        const headers = new HttpHeaders().set("x-api-key", this._config.searchAPIKey);


        return headers;
    }
}
