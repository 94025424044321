/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
    Component, ViewContainerRef
} from "@angular/core";
import {
    Router, Event, NavigationEnd
} from "@angular/router";

declare let window: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent {

    showMobileMenu: boolean;

    showDropdown: boolean;

    currentYear: number;

    navEnd: any;

    constructor(
        private readonly _router: Router,
        public viewContainerRef: ViewContainerRef
    ) {

        this.viewContainerRef = viewContainerRef;

        this.currentYear = new Date().getFullYear();

        this._router.events.subscribe(
            (event: Event) => {

                if (event instanceof NavigationEnd) {
                    this.navEnd = event;
                    window.scrollTo(0, 0);
                    window.dataLayer.push({
                        event: "pageView",
                        action: event.urlAfterRedirects
                    });
                }

                this.toggleMobileMenu("hide");
                this.toggleDropdown("hide");
            }
        );

        window.addEventListener("resize", () => {

            this.toggleMobileMenu("hide");
        }, true);
    }


    toggleMobileMenu(force?: string): void {

        if (force) {
            this.showMobileMenu = false;
        }
        else {
            this.showMobileMenu = !this.showMobileMenu;
        }
    }


    toggleDropdown(force?: string): void {

        let productsPage = false;

        if (this.navEnd) {
            if (this.navEnd.url === "/products") {
                productsPage = true;
            }
        }

        if (!productsPage) {
            switch (force) {
            case "show":
                this.showDropdown = true;
                break;
            case "hide":
                this.showDropdown = false;
                break;
            default:
                this.showDropdown = !this.showDropdown;
            }
        }
    }
}
