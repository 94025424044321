<!-- Dynamic Section: Instructions -->
<section class="section-instructions">
    <span class="focus-trap" tabindex="-1"></span>
    <div class="instructions-wrapper" [@instructionsVisibility] *ngIf="instructionsVisibilityState">
        <mat-icon (click)="closeInstructions()">cancel</mat-icon>

        <div class="instructions-view-wrapper">
            <div class="iteration-wrapper" *ngFor="let view of configuration; let $index = index"
                [ngClass]="{'active': instructionsNavIndex === $index}">
                <div class="instructions-view generic"
                        [ngClass]="{'transitioning': instructionsNavIndex === -1}"
                        *ngIf="instructionsNavIndex === $index || instructionsNavIndex === -1"
                >
                    <div [class]="view.type + ' demo-item'">
                        <h2>{{ view.heading }}</h2>

                        <div [ngSwitch]="view.type">
                            <div data-layout="row wrap" *ngSwitchCase="'size'">
                                <div data-flex *ngFor="let item of view.set" class="selection-container">
                                    <a class="selection-wrapper" (click)="selectOption(view, item)" [ngClass]="{'selected': isSelected(view.type, item.value)}">
                                        <div class="size-block"
                                            [ngStyle]="{'height': (resolutionScaleFactor * +item.value.split(';')[1]) + 'px',
                                                        'width': (resolutionScaleFactor * +item.value.split(';')[0]) + 'px'}">
                                            <span class="label">{{item.label}}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div data-layout="row wrap" *ngSwitchDefault>
                                <div data-flex *ngFor="let item of view.set" class="selection-container">
                                    <a class="selection-wrapper" (click)="selectOption(view, item)" [ngClass]="{'selected': isSelected(view.type, item.value)}">
                                        <div class="demo-item-image" *ngIf="item.image">
                                            <img [src]="item.image" alt="" />
                                        </div>

                                        <h3 class="demo-item-label">{{ item.label }}</h3>

                                        <div class="demo-item-description" *ngIf="item.description?.length">
                                            {{ item.description.join(', ') }}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="instructions-view location" [ngClass]="{'transitioning': instructionsNavIndex === -1}"
                 *ngIf="instructionsNavIndex === (instructionsNavItems.length - 2) || instructionsNavIndex === -1">
                <h2>{{ locations?.length ? 'Choose a city' : 'Enter a ZIP Code' }}</h2>

                <div class="location-wrapper">
                    <form>
                        <mat-form-field class="dark">
                            <zip-selector [(ngModel)]="zipcode" (ngModelChange)="updateLocation()" [presetLocations]="locations" name="zip"
                                    *ngIf="!locations || !forcePresetLocations" [placeholder]="locations?.length ? 'city' : 'zip'">
                            </zip-selector>

                            <mat-select [(ngModel)]="zipcode" (ngModelChange)="updateLocation()" name="zip" placeholder="Zip"
                                    *ngIf="locations && forcePresetLocations">
                                <mat-option *ngFor="let location of locations" [value]="location.zip">
                                    {{ location.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>

                <button (click)="showNextInstruction()" class="btn btn-primary btn-progress" *ngIf="zipcode">
                    NEXT
                </button>
            </div>

            <div class="instructions-view retailers" [ngClass]="{'transitioning': instructionsNavIndex === -1}"
                 *ngIf="showRetailers && (instructionsNavIndex === (instructionsNavItems.length - 1) || instructionsNavIndex === -1)">
                <h2>We found {{totalRetailers}} Retailers Nearby</h2>

                <div *ngIf="retailers.length">
                    <div class="retailers-wrapper" *ngFor="let retailer of retailers">
                        <img [src]="retailer.imageUrl" [alt]="retailer.name" />
                    </div>
                </div>

                <p *ngIf="!retailers.length">Sorry! Try updating your location. In the meantime, we'll show you the nearest store for your default retailer (Target)</p>

                <button (click)="closeInstructions()" class="btn btn-primary btn-progress">
                    See My Ad!
                </button>
            </div>

            <div class="instructions-view complete" [ngClass]="{'transitioning': instructionsNavIndex === -1}"
                 *ngIf="!showRetailers && (instructionsNavIndex === (instructionsNavItems.length - 1) || instructionsNavIndex === -1)">
                <h2>You're All Done!</h2>

                <button (click)="closeInstructions()" class="btn btn-primary btn-progress">
                    See My Ad!
                </button>
            </div>
        </div>

        <div class="instructions-nav">
            <a tabindex="1" (click)="showPreviousInstruction()" class="nav-previous"
                [ngClass]="{'invisible': instructionsNavIndex === 0}">
                <mat-icon>chevron_left</mat-icon>
            </a>

            <a tabindex="1" (click)="setInstructionsNavIndex($index)" class="nav-item"
                [ngClass]="{'active': instructionsNavIndex === $index}"
               *ngFor="let item of instructionsNavItems; let $index = index">
            </a>

            <a tabindex="1" (click)="showNextInstruction()" class="nav-next"
                [ngClass]="{'invisible': instructionsNavIndex === (configuration.length + 1)}">
                <mat-icon>chevron_right</mat-icon>
            </a>
        </div>
    </div>
    <span class="focus-trap" tabindex="-1"></span>
</section>

<!-- End Dynamic Section -->

<div [ngClass]="{'instructions-open': instructionsVisibilityState}">

    <!-- Dynamic Section: Filters/Ad -->

    <section class="demo-section-2 section-background-light">
        <div class="ad-unit-wrapper">
            <iframe id="demoAdUnit" class="demo-ad-unit" [src]="adSrc"
                    [ngStyle]="getFrameDimensions()">
            </iframe>
        </div>
        <div class="ad-options-wrapper">
            <div class="demo-section">
                <form>
                    <div class="demo-section-step" *ngFor="let item of configuration; let i = index">
                        <div [ngSwitch]="item.selectorType" class="step-input">
                            <div *ngSwitchCase="'button'" class="step-button-select">
                                <h5>
                                    {{ (i + 1).toString() + '. ' + item.heading }}
                                </h5>

                                <a *ngFor="let option of item.set" (click)="selectOption(item, option)"
                                        [ngClass]="{'selected': isSelected(item.type, option.value)}">

                                    <div *ngIf="option.image" class="option-image" [style.background-image]="getImageSource(option.image)"></div>

                                    <span>{{ option.label }}</span>
                                </a>
                            </div>
                            <div *ngSwitchDefault class="step-select">
                                <mat-form-field>
                                    <mat-select [ngModel]="selectedValues.get(item.type)" (selectionChange)="selectOption(item, $event)"
                                            [placeholder]="(i + 1).toString() + '. ' + item.heading" [name]="item.type">
                                        <mat-option *ngFor="let option of item.set" [value]="option.value">{{ option.label }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="demo-section-step step-location">
                        <h5>{{configuration.length + 1}}. Set the location</h5>

                        <p>
                            Where do you want to see deals?
                        </p>

                        <div class="step-input">
                            <mat-form-field>
                                <zip-selector [(ngModel)]="zipcode" (ngModelChange)="updateLocation()" [presetLocations]="locations" name="zip"
                                        *ngIf="!locations || !forcePresetLocations" [placeholder]="locations?.length ? 'city' : 'zip'">
                                </zip-selector>

                                <mat-select [(ngModel)]="zipcode" (ngModelChange)="updateLocation()" name="zip" placeholder="Zip"
                                        *ngIf="locations && forcePresetLocations">
                                    <mat-option *ngFor="let location of locations" [value]="location.zip">
                                        {{ location.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="seeMorePath" class="demo-section-step step-see-more">
                        <a class="btn btn-primary" [href]="seeMorePath">
                            SEE MORE
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </section>

    <!-- End Dynamic Section -->
</div>
