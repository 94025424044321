import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import {
    NotificationModule
} from "@shopliftr/common-ng";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NotificationModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule
    ],
    exports: [
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule
    ]
})
export class SharedModule {}
