/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
    Injectable, Inject
} from "@angular/core";
import {
    Observable, of
} from "rxjs";


export enum SELECTORTYPE {
    BUTTON = "button",
    SELECT = "select"
}


export interface IDemo {
    data: Array<IDemoData>;
    settings: IDemoSettings;
}


export interface IDemoSettings {
    config: Array<IDemoConfig>;
    chains?: Array<string>;
    forcePresetLocations?: boolean;
    showRetailers?: boolean;
    seeMorePath?: string;
}


export interface IDemoConfig {
    type: string;
    heading: string;
    selectorType: string;
    set: Array<IDemoConfigItem>;
}


export interface IDemoConfigItem {
    value: string;
    label: string;
    image?: string;
    description?: Array<string>;
}


export interface IDemoLocation {
    zip: string;
    label: string;
}


export interface IDemoData {
    conditions: Map<string, string>;
    adId: string;
    variationId?: string;
    zips?: Array<IDemoLocation>;
}


@Injectable({
    providedIn: "root"
})
export class SmartAdsService {

    // At this point the AppConfig object does not exist, so we use the placeholder as a means of injecting it later
    private readonly _assetsUrlPlaceholder = "**PLACEHOLDER**";

    private readonly _adData: Map<string, IDemo> = new Map<string, IDemo>([
        [
            "pepsi",
            {
                data: [
                    {
                        conditions: new Map<string, string>([
                            ["persona", "male"],
                            ["time", "pregame"]
                        ]),
                        adId: "d87ae4ac-0f81-4763-9701-ba9d4fdfcd15",
                        zips: [
                            {
                                zip: "48203",
                                label: "Detroit, MI"
                            },
                            {
                                zip: "75204",
                                label: "Dallas, TX"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["persona", "male"],
                            ["time", "halftime"]
                        ]),
                        adId: "387c702a-e221-40ba-813f-2e4700a90834",
                        zips: [
                            {
                                zip: "48203",
                                label: "Detroit, MI"
                            },
                            {
                                zip: "75204",
                                label: "Dallas, TX"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["persona", "female"],
                            ["time", "pregame"]
                        ]),
                        adId: "bfd78cb7-612d-462a-9746-f6f75630b86d",
                        zips: [
                            {
                                zip: "28203",
                                label: "Charlotte, NC"
                            },
                            {
                                zip: "10036",
                                label: "New York, NY"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["persona", "female"],
                            ["time", "halftime"]
                        ]),
                        adId: "0c5849cb-0f0b-4104-81bb-30873553b4c1",
                        zips: [
                            {
                                zip: "28203",
                                label: "Charlotte, NC"
                            },
                            {
                                zip: "10036",
                                label: "New York, NY"
                            }
                        ]
                    }
                ],
                settings: {
                    config: [
                        {
                            type: "persona",
                            heading: "Choose a persona",
                            selectorType: SELECTORTYPE.BUTTON,
                            set: [
                                {
                                    value: "male",
                                    label: "Millennial Mark",
                                    image: this._assetsUrlPlaceholder + "AC-31/persona-male.png"
                                },
                                {
                                    value: "female",
                                    label: "Healthy Heather",
                                    image: this._assetsUrlPlaceholder + "AC-31/persona-female.png"
                                }
                            ]
                        },
                        {
                            type: "time",
                            heading: "What's your break?",
                            selectorType: SELECTORTYPE.BUTTON,
                            set: [
                                {
                                    value: "pregame",
                                    label: "Pre-Game",
                                    image: this._assetsUrlPlaceholder + "SMS-144/time-pregame.png"
                                },
                                {
                                    value: "halftime",
                                    label: "Half Time",
                                    image: this._assetsUrlPlaceholder + "SMS-144/time-halftime.png"
                                }
                            ]
                        }
                    ],
                    forcePresetLocations: true
                }
            }
        ],
        [
            "p2p",
            {
                data: [
                    {
                        conditions: new Map<string, string>([
                            ["product", "kraft"],
                            ["size", "300;600"],
                            ["persona", "college"]
                        ]),
                        adId: "09718851-950d-4597-a914-353154c99136",
                        zips: [
                            {
                                zip: "60610",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "90210",
                                label: "Beverly Hills, CA"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["product", "kraft"],
                            ["size", "300;600"],
                            ["persona", "mom"]
                        ]),
                        adId: "bee5f1ae-670d-4bb7-8f42-c855a790ac32",
                        zips: [
                            {
                                zip: "60610",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "90210",
                                label: "Beverly Hills, CA"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["product", "kraft"],
                            ["size", "160;600"],
                            ["persona", "college"]
                        ]),
                        adId: "6c2ac784-1b04-4c7f-9c9c-0a9e93c763ee",
                        zips: [
                            {
                                zip: "60610",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "90210",
                                label: "Beverly Hills, CA"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["product", "kraft"],
                            ["size", "160;600"],
                            ["persona", "mom"]
                        ]),
                        adId: "6b1e03a9-495b-49bd-bd4e-6738939228c3",
                        zips: [
                            {
                                zip: "60610",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "90210",
                                label: "Beverly Hills, CA"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["product", "kraft"],
                            ["size", "300;250"],
                            ["persona", "college"]
                        ]),
                        adId: "a70b167f-e42a-4826-a9df-e8de5b89c3b1",
                        zips: [
                            {
                                zip: "60610",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "90210",
                                label: "Beverly Hills, CA"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["product", "kraft"],
                            ["size", "300;250"],
                            ["persona", "mom"]
                        ]),
                        adId: "cb4ab26e-c4f9-45b2-9c04-8a377e027522",
                        zips: [
                            {
                                zip: "60610",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "90210",
                                label: "Beverly Hills, CA"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["product", "tide"],
                            ["size", "300;600"],
                            ["persona", "college"]
                        ]),
                        adId: "ae021f48-da15-41e2-94c0-3a60dc38cc73",
                        zips: [
                            {
                                zip: "60610",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "90210",
                                label: "Beverly Hills, CA"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["product", "tide"],
                            ["size", "300;600"],
                            ["persona", "mom"]
                        ]),
                        adId: "37f0aead-85b5-4227-a962-4cc0b0acd2a7",
                        zips: [
                            {
                                zip: "60610",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "90210",
                                label: "Beverly Hills, CA"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["product", "tide"],
                            ["size", "160;600"],
                            ["persona", "college"]
                        ]),
                        adId: "6f9b97dd-6952-4af8-b528-574ad63bace3"
                    },
                    {
                        conditions: new Map<string, string>([
                            ["product", "tide"],
                            ["size", "160;600"],
                            ["persona", "mom"]
                        ]),
                        adId: "0de59fa0-91dc-49ae-b491-720154eb7cf6",
                        zips: [
                            {
                                zip: "60610",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "90210",
                                label: "Beverly Hills, CA"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["product", "tide"],
                            ["size", "300;250"],
                            ["persona", "college"]
                        ]),
                        adId: "de9e8d31-44b8-48bf-abb5-df1bc6e543bc",
                        zips: [
                            {
                                zip: "60610",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "90210",
                                label: "Beverly Hills, CA"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["product", "tide"],
                            ["size", "300;250"],
                            ["persona", "mom"]
                        ]),
                        adId: "e65ca543-396a-4bdd-958a-5a700d91cd4f",
                        zips: [
                            {
                                zip: "60610",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "90210",
                                label: "Beverly Hills, CA"
                            }
                        ]
                    }
                ],
                settings: {
                    config: [
                        {
                            type: "product",
                            heading: "Choose a product",
                            selectorType: SELECTORTYPE.SELECT,
                            set: [
                                {
                                    label: "Kraft",
                                    value: "kraft",
                                    image: this._assetsUrlPlaceholder + "SMS-120/logo-kraft.png"
                                },
                                {
                                    label: "Tide",
                                    value: "tide",
                                    image: this._assetsUrlPlaceholder + "SMS-120/logo-tide.png"
                                }
                            ]
                        },
                        {
                            type: "size",
                            heading: "Select a size",
                            selectorType: SELECTORTYPE.SELECT,
                            set: [
                                {
                                    label: "160 x 600",
                                    value: "160;600"
                                },
                                {
                                    label: "300 x 600",
                                    value: "300;600"
                                },
                                {
                                    label: "300 x 250",
                                    value: "300;250"
                                }
                            ]
                        },
                        {
                            type: "persona",
                            heading: "Choose a persona",
                            selectorType: SELECTORTYPE.BUTTON,
                            set: [
                                {
                                    label: "Mark, 19",
                                    value: "college",
                                    image: this._assetsUrlPlaceholder + "SMS-120/persona-image-college.png",
                                    description: [
                                        "College student",
                                        "Limited budget",
                                        "lives on campus"
                                    ]
                                },
                                {
                                    label: "Sarah, 37",
                                    value: "mom",
                                    image: this._assetsUrlPlaceholder + "SMS-120/persona-image-mom.jpg",
                                    description: [
                                        "Mother of two",
                                        "College educated",
                                        "Suburban homeowner",
                                        "Active lifestyle"
                                    ]
                                }
                            ]
                        }
                    ],
                    chains: [
                        "44",
                        "39",
                        "53",
                        "64",
                        "73",
                        "151",
                        "217",
                        "241",
                        "240",
                        "194",
                        "45",
                        "131",
                        "89",
                        "187",
                        "170",
                        "195",
                        "204",
                        "168",
                        "190",
                        "123",
                        "135",
                        "4",
                        "174",
                        "143",
                        "10"
                    ],
                    showRetailers: true
                }
            }
        ],
        [
            "size",
            {
                data: [
                    {
                        conditions: new Map<string, string>([
                            ["size", "300;250"]
                        ]),
                        adId: "021d1f01-bb9a-45b2-922b-23f95ce889a1",
                        variationId: "1"
                    },
                    {
                        conditions: new Map<string, string>([
                            ["size", "300;600"]
                        ]),
                        adId: "b9f33fac-37a3-4ba8-865b-050a83c039a9",
                        variationId: "1"
                    }
                ],
                settings: {
                    config: [
                        {
                            type: "size",
                            heading: "Select an ad size",
                            selectorType: SELECTORTYPE.SELECT,
                            set: [
                                {
                                    label: "300 x 250",
                                    value: "300;250"
                                },
                                {
                                    label: "300 x 600",
                                    value: "300;600"
                                }
                            ]
                        }
                    ]
                }
            }
        ],
        [
            "vicks",
            {
                data: [
                    {
                        conditions: new Map<string, string>([
                            ["persona", "male"],
                            ["time", "day"]
                        ]),
                        adId: "72151d22-5016-4336-84c0-7e9f07cad94e",
                        variationId: "1",
                        zips: [
                            {
                                zip: "10028",
                                label: "New York, NY"
                            },
                            {
                                zip: "33135",
                                label: "Miami, FL"
                            },
                            {
                                zip: "60654",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "77067",
                                label: "Houston, TX"
                            },
                            {
                                zip: "90064",
                                label: "Los Angeles, CA"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["persona", "female"],
                            ["time", "day"]
                        ]),
                        adId: "2d602574-17df-43a3-a4e9-2b0dc2d09452",
                        variationId: "2",
                        zips: [
                            {
                                zip: "10028",
                                label: "New York, NY"
                            },
                            {
                                zip: "33135",
                                label: "Miami, FL"
                            },
                            {
                                zip: "60654",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "77067",
                                label: "Houston, TX"
                            },
                            {
                                zip: "90064",
                                label: "Los Angeles, CA"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["persona", "male"],
                            ["time", "night"]
                        ]),
                        adId: "72151d22-5016-4336-84c0-7e9f07cad94e",
                        variationId: "2",
                        zips: [
                            {
                                zip: "10028",
                                label: "New York, NY"
                            },
                            {
                                zip: "33135",
                                label: "Miami, FL"
                            },
                            {
                                zip: "60654",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "77067",
                                label: "Houston, TX"
                            },
                            {
                                zip: "90064",
                                label: "Los Angeles, CA"
                            }
                        ]
                    },
                    {
                        conditions: new Map<string, string>([
                            ["persona", "female"],
                            ["time", "night"]
                        ]),
                        adId: "2d602574-17df-43a3-a4e9-2b0dc2d09452",
                        variationId: "1",
                        zips: [
                            {
                                zip: "10028",
                                label: "New York, NY"
                            },
                            {
                                zip: "33135",
                                label: "Miami, FL"
                            },
                            {
                                zip: "60654",
                                label: "Chicago, IL"
                            },
                            {
                                zip: "77067",
                                label: "Houston, TX"
                            },
                            {
                                zip: "90064",
                                label: "Los Angeles, CA"
                            }
                        ]
                    }
                ],
                settings: {
                    config: [
                        {
                            type: "persona",
                            heading: "Choose a persona",
                            selectorType: SELECTORTYPE.BUTTON,
                            set: [
                                {
                                    value: "male",
                                    label: "Millennial Mark",
                                    image: this._assetsUrlPlaceholder + "AC-31/persona-male.png"
                                },
                                {
                                    value: "female",
                                    label: "Healthy Heather",
                                    image: this._assetsUrlPlaceholder + "AC-31/persona-female.png"
                                }
                            ]
                        },
                        {
                            type: "time",
                            heading: "Choose a time of day",
                            selectorType: SELECTORTYPE.BUTTON,
                            set: [
                                {
                                    value: "day",
                                    label: "Day",
                                    image: this._assetsUrlPlaceholder + "SMS-144/time-day.png"
                                },
                                {
                                    value: "night",
                                    label: "Night",
                                    image: this._assetsUrlPlaceholder + "SMS-144/time-night.png"
                                }
                            ]
                        }
                    ]
                }
            }
        ]
    ]);


    constructor(@Inject("AppConfig") private readonly _appConfig: any) {

        this._replacePlaceholder(this._appConfig.demoAssetsUrlPrefix);
    }


    /**
     * Retrieves the configuration object associated with a given demo
     *
     * @param {string} slug The route of the desired demo
     * @returns {*} The configuration object associated with the given demo. If the demo is not configured, throws an error
     * @memberof SmartAdsService
     */
    getConfiguration(slug: string): IDemoSettings {

        if (!this._adData.has(slug)) {
            throw new Error("Configuration not available");
        }

        return this._adData.get(slug).settings;
    }


    /**
     * Retrieves the data object associated with a given demo
     *
     * @param {string} slug
     * @returns {Observable<any>}
     * @memberof SmartAdsService
     */
    getData(slug: string): Observable<Array<IDemoData>> {

        if (!this._adData.has(slug)) {
            throw new Error("Configuration not available");
        }

        return of(this._adData.get(slug).data);
    }


    /**
     * Checks the given keyset against the defined data and determines the appropriate endpoint for the requested ad
     *
     * @param {string} slug The route of the desired demo
     * @param {*} data The payload for selecting an ad
     * @returns {string} The URL of the desired ad, or an empty string if there is no match
     * @memberof SmartAdsService
     */
    getEndpoint(slug: string, data: Map<string, string>): IDemoData {

        if (!this._adData.has(slug)) {
            throw new Error("Endpoint not available");
        }

        // Use the service's key as the basis for comparison to minimise processing
        for (const demo of this._adData.get(slug).data) {
            let passed = true;

            demo.conditions.forEach((value: string, key: string) => {

                if (value !== data.get(key)) {
                    passed = false;
                }
            });

            if (passed) {
                return demo;
            }
        }

        return undefined;
    }


    /**
     * Scans through the configurations for the stored demos and fixes the placeholder URL
     *
     * @private
     * @param {string} newString The replacement URL for the placeholder string
     * @memberof SmartAdsService
     */
    private _replacePlaceholder(newString: string): void {

        this._adData.forEach((value: IDemo) => {

            this._searchAndReplace(value.settings.config, this._assetsUrlPlaceholder, newString);
        });
    }


    /**
     * Recursively searches through a structure to replace all occurrences of oldString with newString
     *
     * @private
     * @param {*} target Target object
     * @param {string} oldString String to be replaced
     * @param {string} newString String to replace oldString
     * @memberof SmartAdsService
     */
    private _searchAndReplace(target: any, oldString: string, newString: string): void {

        for (const key of Object.keys(target)) {
            switch (typeof (target[key])) {
            case "string":
                target[key] = target[key].replace(oldString, newString);

                break;
            case "object":
                this._searchAndReplace(target[key], oldString, newString);

                break;
            default:
                continue;
            }
        }
    }
}
