import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    FormsModule, ReactiveFormsModule
} from "@angular/forms";
import { A11yModule } from "@angular/cdk/a11y";

import { ZipSelectorComponent } from "./zip-selector.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatOptionModule } from "@angular/material/core";


@NgModule({
    imports: [
        A11yModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatOptionModule
    ],
    providers: [],
    declarations: [
        ZipSelectorComponent
    ],
    exports: [
        ZipSelectorComponent
    ]
})
export class ZipSelectorModule {}
