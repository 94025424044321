import { Language } from "@shopliftr/common-js/shared";
import { IDemo } from "./interfaces/demo";

/**
 * Data structure responsible for build the data for each demo.
 */
export const DEMO_DATA : Array<IDemo> = [
    {
        "id": "grocery",
        "name": "Grocery",
        "image": "/assets/images/logo-genjis.png",
        "languages": [Language.English, Language.Spanish, Language.French],
        "cities": [
            {
                "image": "/assets/images/city-montreal.jpg",
                "name": "Montreal",
                "languages": [Language.English, Language.French],
                "latitude": 45.551705,
                "longitude": -73.5641373
            },
            {
                "image": "/assets/images/city-calgary.jpg",
                "name": "Calgary",
                "languages": [Language.English, Language.French],
                "latitude": 51.16991789999999,
                "longitude": -114.1516704
            },
            {
                "image": "/assets/images/city-boise.jpg",
                "name": "Boise",
                "languages": [Language.English, Language.Spanish],
                "latitude": 43.6158485,
                "longitude": -116.2206625
            },
            {
                "image": "/assets/images/city-hampton.jpg",
                "name": "Hampton",
                "languages": [Language.English, Language.Spanish],
                "latitude": 37.0122377,
                "longitude": -76.3540508
            }
        ],
        "videoAdTagId": "ea0025fd-7956-4aac-882d-01f7727a2d65",
        "displayAdTagId": "3871b10f-3b18-44c9-8fc0-b209ab5d1fc1",
        "tapToMapDesktopAdTagId": "786b92ee-701c-4db9-a50a-a7a0aa078a02",
        "tapToMapMobileAdTagId": "15893d87-7328-44b6-a0be-6753ff66d38b"
    },
    {
        "id": "product",
        "name": "Product",
        "image": "/assets/images/logo-energy.png",
        "languages": [Language.English, Language.Spanish, Language.French],
        "cities": [
            {
                "image": "/assets/images/city-montreal.jpg",
                "name": "Montreal",
                "languages": [Language.English, Language.French],
                "latitude": 45.551705,
                "longitude": -73.5641373
            },
            {
                "image": "/assets/images/city-toronto.jpg",
                "name": "Toronto",
                "languages": [Language.English, Language.French],
                "latitude": 43.6874428,
                "longitude": -79.3048124
            },
            {
                "image": "/assets/images/city-newark.jpg",
                "name": "Newark",
                "languages": [Language.English, Language.Spanish],
                "latitude": 40.7366424,
                "longitude": -74.1861976
            },
            {
                "image": "/assets/images/city-minneapolis.jpg",
                "name": "Minneapolis",
                "languages": [Language.English, Language.Spanish],
                "latitude": 45.20565329999999,
                "longitude": -93.3617307
            }
        ],
        "videoAdTagId": "6d0bc5a5-c16e-450d-9cde-9242dc156fa9",
        "displayAdTagId": "584f709b-ff2f-4b93-b232-38cbc5cf7b0b",
        "tapToMapDesktopAdTagId": "8799ca2e-95a9-4fb3-93b7-ac7e812aa65a",
        "tapToMapMobileAdTagId": "b486db22-ca19-46ed-b4c4-3b5a4d980245"
    }
];
