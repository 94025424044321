import { BrowserModule } from "@angular/platform-browser";
import {
    ErrorHandler, NgModule
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { environment } from "../environments/environment";

import { AppRoutingModule } from "./app-routing.module";

import { DemoModule } from "./demo/demo.module";
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";
import { CommonErrorHandler } from "@shopliftr/common-ng";


// TODO: The AppRoutingModule must be the last import currently. Routing needs to be rethought for this app
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        DemoModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        SharedModule,
        AppRoutingModule
    ],
    providers: [
        {
            provide: "AppConfig",
            useValue: environment
        },
        {
            provide: ErrorHandler,
            useClass: CommonErrorHandler
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}
