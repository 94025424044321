<nav class="navbar">
    <div class="navbar-content">
        <div class="button-wrapper">
            <button (click)="onDemoGalleryClick()" *ngIf="showToDemoGalleryButton" mat-button>
                <mat-icon>arrow_back</mat-icon>
                <span>Choose a Scenario</span>
            </button>
        </div>
        <div class="navbar-logo">
            <a href="https://www.shopliftr.com"><img src="/assets/images/logo-white-shopliftr.svg" alt="ShopLiftr | All-in-One Digital Shopper Marketing Solution"/></a>
        </div>
    </div>
</nav>
<div class="app-content">
    <router-outlet></router-outlet>
</div>
