/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
    production: true,
    searchAPIKey: "GrtzCt8aHl8VuEQyhHusm7GdAau1GJlaa7GvkpD2",
    searchAPIUrl: "https://api.mygrocerydeals.com",
    adUrlPrefix: "https://demo.ads.mygrocerydeals.com",
    pagesUrlPrefix: "https://pages.myshopliftr.com",
    emailEndpoint: "https://internal-api.mygrocerydeals.com/contact/shopliftr",
    demoAssetsUrlPrefix: "https://assets.ads.mygrocerydeals.com/static/",
    rollbar: {
        enabled: true,
        environment: "production",
        accessToken: "1209dc2b06b745db9f3c4ad700784695"
    }
};
