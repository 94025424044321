import {
    Component, OnInit, Inject
} from "@angular/core";
import {
    trigger, transition, style, animate
} from "@angular/animations";
import {
    SafeUrl, SafeResourceUrl, DomSanitizer
} from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

import { Chain } from "@shopliftr/common-js/shared";

import {
    IDemoConfig, IDemoConfigItem, IDemoData, SmartAdsService, IDemoLocation
} from "../../services/smart-ads.service";
import { MatSelectChange } from "@angular/material/select";
import { ChainService } from "src/app/services/chain.service";


@Component({
    selector: "smart-ads",
    templateUrl: "./smart-ads.component.html",
    styleUrls: ["./smart-ads.component.scss"],
    animations: [
        trigger("instructionsVisibility", [
            // For use with ngIf
            transition(":enter", [
                style({
                    "bottom": "50%",
                    "left": "50%",
                    "opacity": "0.0",
                    "right": "50%",
                    "top": "50%"
                }),
                animate("500ms", style({
                    "bottom": "0",
                    "left": "0",
                    "opacity": "1.0",
                    "right": "0",
                    "top": "0"
                }))
            ]),
            transition(":leave", [
                style({
                    "bottom": "0",
                    "left": "0",
                    "opacity": "1.0",
                    "right": "0",
                    "top": "0"
                }),
                animate("500ms", style({
                    "bottom": "50%",
                    "left": "50%",
                    "opacity": "0.0",
                    "right": "50%",
                    "top": "50%"
                }))
            ])
        ])
    ]
})
export class SmartAdsComponent implements OnInit {

    environmentUrl: string;

    instructionsNavIndex: number;

    instructionsNavItems: Array<string>;

    instructionsVisibilityState: boolean;

    resolutionScaleFactor: number;

    adSrc: SafeResourceUrl;

    zipcode: string;

    configuration: Array<IDemoConfig>;

    chainIds: Array<string>;

    demo: string;

    forcePresetLocations: boolean;

    locations: Array<IDemoLocation>;

    retailers: Array<Chain>;

    seeMorePath: SafeUrl;

    showRetailers: boolean;

    totalRetailers: number;

    selectedValues = new Map<string, string>();


    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _chainService: ChainService,
        private readonly _sanitizer: DomSanitizer,
        private readonly _smartAdsService: SmartAdsService,
        @Inject("AppConfig") private readonly _appConfig: any
    ) {

        this.environmentUrl = `${this._appConfig.adUrlPrefix}/campaign`;
        this.demo = this._activatedRoute.snapshot.params["view"];
    }


    ngOnInit(): void {

        const baseConfig = this._smartAdsService.getConfiguration(this.demo);

        this.chainIds = baseConfig.chains;
        this.forcePresetLocations = baseConfig.forcePresetLocations;
        this.showRetailers = baseConfig.showRetailers;

        if (baseConfig.seeMorePath) {
            this.seeMorePath = this._sanitizeUrl(baseConfig.seeMorePath);
        }

        this.configuration = new Array<IDemoConfig>();

        for (const configObject of baseConfig.config) {
            this.selectedValues.set(configObject.type, configObject.set[0].value);
            this.configuration.push(configObject);
        }

        this.instructionsVisibilityState = true;
        this.instructionsNavIndex = 0;

        this.instructionsNavItems = [];

        for (const configObject of this.configuration) {
            this.instructionsNavItems.push(configObject.type);
        }

        this.instructionsNavItems.push("location");

        if (this.showRetailers) {
            this.instructionsNavItems.push("retailers");

            this.retailers = new Array<Chain>();
        }
        else {
            // Last element: "See my ad!"
            this.instructionsNavItems.push("static");
        }

        if (window.screen.width < 600) {
            this.resolutionScaleFactor = 0.25;
        }
        else if (window.screen.width < 800) {
            this.resolutionScaleFactor = 0.5;
        }
        else {
            this.resolutionScaleFactor = 1;
        }

        this.getAdSource();
    }


    /**
     * Constructs a valid ad endpoint from its component pieces
     *
     * @param {string} adId The UUID of the ad
     * @param {string} [variationId] The ID of the targeted variation
     * @param {string} [zip] The zip associated with the current view
     * @returns {string}
     * @memberof SmartAdsComponent
     */
    buildUrl(adId: string, variationId?: string, zip?: string): string {

        let url = `${this.environmentUrl}/${adId}`;

        if (variationId || zip) {
            url += "?";

            const segments = new Array<string>();

            if (variationId) {
                segments.push(`variation=${variationId}`);
            }

            if (zip) {
                segments.push(`zip=${zip}`);
            }

            url += segments.join("&");
        }

        return url;
    }


    /**
     * Hides the instructions
     *
     * @memberof SmartAdsComponent
     */
    closeInstructions(): void {

        this.instructionsVisibilityState = false;
    }


    /**
     * Populates the ad frame's source if the parameters are valid
     *
     * @memberof SmartAdsComponent
     */
    getAdSource(): void {

        const urlData: IDemoData = this._smartAdsService.getEndpoint(this.demo, this.selectedValues);

        if (urlData) {
            this.adSrc = this._sanitizeUrl(this.buildUrl(urlData.adId, urlData.variationId, this.zipcode));
            this.locations = urlData.zips;
        }
    }


    /**
     * Gets the appropriate height and width for the ad frame based on current configuration data
     *
     * @returns {{ height: string, width: string}}
     * @memberof SmartAdsComponent
     */
    getFrameDimensions(): { height: string; width: string} {

        if (this.selectedValues.has("size")) {
            const dimensions = this.selectedValues.get("size").split(";");

            return {
                height: `${dimensions[1]}px`,
                width: `${dimensions[0]}px`
            };
        }

        // Default
        return {
            height: "250px",
            width: "300px"
        };
    }


    /**
     * Sanitizes the given image path and returns a safe URL
     *
     * @param path The path to the image
     */
    getImageSource(path: string): string {

        return path ? `url(${path})` : "";
    }


    /**
     * Determines whether the given value is selected for the given parameter
     *
     * @param {string} parameter
     * @param {string} value
     * @returns {boolean}
     * @memberof SmartAdsComponent
     */
    isSelected(parameter: string, value: string): boolean {

        return (this.selectedValues.get(parameter) === value);
    }


    /**
     * Sets the given object to be the selected option for the given filter
     *
     * @param {*} filter The filter to be modified
     * @param {*} target The value to select within the given filter
     * @memberof SmartAdsComponent
     */
    selectOption(filter: IDemoConfig, value: IDemoConfigItem | MatSelectChange): void {

        this.selectedValues.set(filter.type, value.value as string);

        this.getAdSource();

        if (this.instructionsVisibilityState) {
            this.showNextInstruction();
        }
    }


    /**
     * Smoothly transitions the instructions view to the desired index
     *
     * @param {number} targetIndex The desired instructions page
     * @memberof SmartAdsComponent
     */
    setInstructionsNavIndex(targetIndex: number): void {

        if (targetIndex !== this.instructionsNavIndex) {
            // Transition previous instruction  out
            this.instructionsNavIndex = -1;

            // Transition current instruciton in
            setTimeout((self) => {

                self.instructionsNavIndex = targetIndex;
            }, 500, this);
        }
    }


    /**
     * Increments the view for the instructions panel
     *
     * @memberof SmartAdsComponent
     */
    showNextInstruction(): void {

        this.setInstructionsNavIndex(this.instructionsNavIndex + 1);
    }


    /**
     * Decrements the view for the instructions panel
     *
     * @memberof SmartAdsComponent
     */
    showPreviousInstruction(): void {

        this.setInstructionsNavIndex(this.instructionsNavIndex - 1);
    }


    /**
     * When the location changes in the zip code field, store the result locally
     *
     * @memberof SmartAdsComponent
     */
    updateLocation(): void {

        this.getAdSource();

        if (this.showRetailers && this.zipcode) {
            this._chainService.availableNearby({ zip: this.zipcode }, null, this.chainIds).subscribe((response: any) => {

                this.retailers = response.results;
                this.totalRetailers = response.count;
            });
        }
    }


    /**
     * Encodes the given URL to be trusted so that it can be used as the source of the ad frame
     *
     * @private
     * @param {string} url Base URL
     * @returns {SafeResourceUrl} URL after processing
     * @memberof SmartAdsComponent
     */
    private _sanitizeUrl(url: string): SafeResourceUrl {

        return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
