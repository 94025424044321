<div class="app-container">
    <nav class="navbar">
        <div class="app-navbar">
            <a class="navbar-logo" href="https://www.shopliftr.com"><img src="../assets/images/logo-white-shopliftr.svg" alt="ShopLiftr | All-in-One Digital Shopper Marketing Solution"/></a>
            <a class="navbar-actions" href="https://www.shopliftr.com/connect">
                <button type="button" name="button" class="btn btn-secondary">REQUEST A QUOTE</button>
            </a>
        </div>
    </nav>
    <div class="app-content">
        <router-outlet></router-outlet>
    </div>
    <footer class="app-footer">
        <div class="footer-container">
            <div class="footer-contact">
                <div class="footer-logo">
                    <a href="/"><img src="../assets/images/logo-white-shopliftr.svg" title="ShopLiftr | All-in-One Digital Shopper Marketing Solution"
                            alt="ShopLiftr | All-in-One Digital Shopper Marketing Solution" /></a>
                </div>
                <address class="footer-address">
                    <p>
                        <a href="mailto:info@myshopliftr.com">info@myshopliftr.com</a>
                    </p>
                    <p>
                        <a href="tel:+18667602525">1-866-760-2525</a>
                    </p>
                </address>
                <div class="footer-social-icon">
                    <a href="https://www.linkedin.com/company/10641838"><img src="../assets/images/linkedin.svg" alt="Connect with ShopLiftr on LinkedIn" /></a>
                </div>
            </div>
            <div class="footer-bottom">
                <span>© ShopLiftr {{currentYear}}</span>
            </div>
        </div>
    </footer>
</div>
