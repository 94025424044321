/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-types */
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { FocusMonitor } from "@angular/cdk/a11y";
import {
    FormControl, ControlValueAccessor, NgControl
} from "@angular/forms";

import { IDemoLocation } from "../../services/smart-ads.service";
import {
    Observable, of, Subject
} from "rxjs";
import {
    startWith, debounceTime, switchMap
} from "rxjs/operators";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { MatFormFieldControl } from "@angular/material/form-field";


@Component({
    selector: "zip-selector",
    templateUrl: "./zip-selector.component.html",
    styleUrls: ["./zip-selector.component.scss"],
    providers: [{
        provide: MatFormFieldControl,
        useExisting: ZipSelectorComponent
    }]
})
export class ZipSelectorComponent implements ControlValueAccessor, MatFormFieldControl<string>,
OnChanges, OnDestroy, OnInit, AfterViewInit {

    static nextId = 0;

    @ViewChild("zipSelectorInput", { static: false }) input: ElementRef;

    @ViewChild("zipAutocomplete", { static: false }) zipAutocomplete: MatAutocomplete;

    @HostBinding() id = `zip-selector-${ZipSelectorComponent.nextId++}`;

    @HostBinding("attr.aria-describedby") describedBy = "";

    @Input() presetLocations: Array<IDemoLocation>;

    @Input() selectOnly: boolean;

    @Output() select = new EventEmitter<string>(false);

    filteredZips: Observable<Array<IDemoLocation>>;

    zipControl = new FormControl();

    controlType = "zip-selector";

    errorState = false;

    focused = false;

    stateChanges = new Subject<void>();

    nextErrorCheck = new Subject<void>();


    onChange: (_: any) => {};

    onTouched: () => {};


    @Input()
    get isDisabled(): boolean {

        return this._disabled;
    }

    set isDisabled(disabled: boolean) {

        this._disabled = coerceBooleanProperty(disabled);
        this.input.nativeElement.disabled = this._disabled;
        this.stateChanges.next();
    }

    private _disabled = false;

    // Required for MatFormFieldControl
    get disabled(): boolean {

        return this._disabled;
    }

    get empty(): boolean {

        return !this.value;
    }

    @Input()
    get placeholder(): string {

        return this._placeholder;
    }

    set placeholder(placeholder: string) {

        this._placeholder = placeholder;
        this.stateChanges.next();
    }

    private _placeholder = "zip";

    @Input()
    get required(): boolean {

        return this._required;
    }

    set required(required: boolean) {

        this._required = coerceBooleanProperty(required);
        this.stateChanges.next();
    }

    private _required = false;

    @HostBinding("class.floating")
    get shouldLabelFloat(): boolean {

        return this.focused || !this.empty || this._shouldLabelFloat;
    }

    set shouldLabelFloat(value: boolean) {

        this._shouldLabelFloat = value;
    }

    private _shouldLabelFloat: boolean;

    get value(): string {

        return this.zipControl.value;
    }

    set value(zip: string) {

        this.stateChanges.next();
        this.select.emit(zip);

        if (this.onChange && !this.errorState) {
            this.onChange(zip);
            this.onTouched();
        }
    }


    constructor(
        private readonly _elementRef: ElementRef,
        private readonly _focusMonitor: FocusMonitor,
        @Optional() @Self() public ngControl: NgControl
    ) {

        if (this.ngControl !== null) {
            this.ngControl.valueAccessor = this;
        }

        this._focusMonitor.monitor((_elementRef.nativeElement as HTMLElement), true).subscribe((origin) => {

            this.focused = coerceBooleanProperty(origin);
            this.stateChanges.next();
        });
    }


    ngOnChanges(changes: SimpleChanges): void {

        const locations = changes["presetLocations"];

        if (locations && !this.compareLocationSets(
            (locations.currentValue as Array<IDemoLocation>), (locations.previousValue as Array<IDemoLocation>)
        )) {

            this.zipControl.setValue(undefined);
        }

        this.debounceErrorState();
    }


    ngOnDestroy(): void {

        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef.nativeElement as HTMLElement);
    }


    ngOnInit(): void {

        // Updates the model whenever the input changes
        this.filteredZips = this.zipControl.valueChanges.pipe(
            startWith(null),
            debounceTime(300),
            switchMap((value) => {

                this.value = value || undefined;

                this.debounceErrorState();

                // The value entered does not affect the autocomplete options
                return of(this.presetLocations || []);
            })
        );

        this.nextErrorCheck.pipe(debounceTime(300)).subscribe({
            next: () => {
                this.setErrorState();
            },
            error: () => {}
        });
    }


    ngAfterViewInit(): void {

        // Ensuring focus control is relegated to the select
        this.input.nativeElement.setAttribute("tabindex", this._elementRef.nativeElement.getAttribute("tabindex"));
        this._elementRef.nativeElement.removeAttribute("tabindex");
    }


    /* ***** ControlValueAccessor ***** */
    registerOnChange(fn: any): void {

        this.onChange = fn;
    }


    registerOnTouched(fn: any): void {

        this.onTouched = fn;
    }


    writeValue(zip: string): void {

        this.zipControl.setValue(zip);

        this.setErrorState();
    }


    /* ***** MatFormFieldControl ***** */
    onContainerClick(): void {

        if (!this.disabled) {
            (this._elementRef.nativeElement as HTMLElement).focus();
        }
    }


    setDescribedByIds(ids: Array<string>): void {

        this.describedBy = ids.join(" ");
    }


    setErrorState(): void {

        const regexZip = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

        this.errorState = this.zipControl.touched && ((this.required && this.empty) || (!this.empty && !regexZip.test(this.value)));
    }


    /* ***** Selector Functions ***** */

    /**
     * Determines whether or not the given sets are the same.
     *
     * @param {Array<IDemoLocation>} a
     * @param {Array<IDemoLocation>} b
     * @returns {boolean}
     * @memberof ZipSelectorComponent
     */
    compareLocationSets(a: Array<IDemoLocation>, b: Array<IDemoLocation>): boolean {

        if (!a && !b) {
            return true;
        }
        else {
            if (a && !b || !a && b) {
                return false;
            }
            else {
                return (JSON.stringify(a) === JSON.stringify(b));
            }
        }
    }


    debounceErrorState(): void {

        this.nextErrorCheck.next();
    }
}
