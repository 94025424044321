import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { DemoRoutingModule } from "./demo-routing.module";

import { SharedModule } from "../shared/shared.module";
import { ZipSelectorModule } from "../shared/zip-selector/zip-selector.module";

import { DemoComponent } from "./demo.component";
import { SmartAdsComponent } from "./smart-ads/smart-ads.component";


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        DemoRoutingModule,
        FormsModule,
        SharedModule,
        ZipSelectorModule
    ],
    declarations: [
        DemoComponent,
        SmartAdsComponent
    ]
})
export class DemoModule {}
