import { NgModule } from "@angular/core";
import {
    Routes, RouterModule
} from "@angular/router";

import { DemoComponent } from "./demo.component";
import { SmartAdsComponent } from "./smart-ads/smart-ads.component";

const demoRoutes: Routes = [
    {
        path: "demo",
        component: DemoComponent,
        children: [
            {
                path: "",
                pathMatch: "full",
                redirectTo: "smart-ads/pepsi"
            },
            {
                path: "smart-ads",
                redirectTo: "smart-ads/pepsi"
            },
            {
                path: "smart-ads/:view",
                component: SmartAdsComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(demoRoutes)],
    exports: [RouterModule],
    providers: []
})
export class DemoRoutingModule { }
