import { Theme } from "../app/enums/theme";
import { appVersion } from "./app-version";
/**
 * Base config used for all the enviroments
 */
export const baseConfig = {
    appVersion: appVersion,
    production: false,
    staging: false,
    displayAdHost: "https://staging.ads.mygrocerydeals.com",
    videoAdHost: "https://staging.ads.mygrocerydeals.com",
    rollbar: {
        enabled: false,
        environment: "development",
        accessToken: "1209dc2b06b745db9f3c4ad700784695"
    },
    displayUncaughtErrorMessage: true,
    logoUrl: "/assets/images/logo-white-shopliftr.svg",
    theme: Theme.Shopliftr
};
