import { Injectable } from "@angular/core";
import { IDemo } from "./interfaces/demo";
import { DEMO_DATA } from "./demo-data";
import {
    ArrayUtil, StringUtil
} from "@shopliftr/common-js/data";

@Injectable({
    providedIn: "root"
})

/**
 *  DemoService class retrieves all the demos or a specific one by id
 *  and validate their data,ad tag IDs, cities, and languages.
 * @export
 * @class DemoService
 */
export class DemoService {

    /**
    * Returns all Available Demos
    */
    getAllDemos(): Array<IDemo> {

        DEMO_DATA.forEach((demo) => {
            this._validateDemo(demo);
        });

        return DEMO_DATA;
    }

    /**
    * Returns the Demos given the id
    * If a demo with the id is not found and error will be thrown
    */
    getDemo(id: string): IDemo {

        const demo = DEMO_DATA.find((demo) => demo.id === id);
        if (demo) {
            this._validateDemo(demo);
            return demo;
        }

        else {
            throw new Error(`Demo with id '${id}' not found.`);
        }
    }

    /**
     *
     * Check if at least one type of tag id is defined, if not, trow a error also a city and a lang
     */
    private _validateDemo(demo: IDemo): void {

        //Checks if we have a ad tag id defined it can be video, mobile, desktop and expandable
        if (StringUtil.emptyString(demo?.displayAdTagId) &&
        StringUtil.emptyString(demo?.videoAdTagId) &&
        StringUtil.emptyString(demo?.tapToMapDesktopAdTagId) &&
        StringUtil.emptyString(demo?.tapToMapMobileAdTagId)) {
            throw new Error("At least one ad tag id must be set for the demo");
        }

        //Checks if we have any cities defined otherwise throws a error
        if (ArrayUtil.isEmpty(demo?.cities)) {
            throw new Error("At least one city must be set for the demo");
        }

        //Checks if we have any languages defined otherwise throws a error
        if (ArrayUtil.isEmpty(demo?.languages)) {
            throw new Error("At least one language must be set for the demo");
        }

    }
}
