<input #zipSelectorInput
        [formControl]="zipControl"
        [matAutocomplete]="zipAutocomplete"
        [required]="required"
        (blur)="debounceErrorState()"
        (input)="debounceErrorState()"
        class="mousetrap" />

<mat-autocomplete #zipAutocomplete="matAutocomplete">
    <mat-option *ngFor="let location of filteredZips | async" [value]="location.zip">
        {{ location.label }}
    </mat-option>
</mat-autocomplete>

